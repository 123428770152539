"use client"
import { useState } from "react";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion";

const Data = [
    {
        id: 0,
        value: 'item1',
        title: 'What does a Certification from ExC Academy mean?',
        content: 'A Certification from ExC Academy is a testament to expertise, a symbol of accomplishment, and a gateway to professional growth and opportunities.',
    },
    {
        id: 1,
        value: 'item2',
        title: "What is the schedule and duration of the course?",
        content: 'The schedule and duration of ExC Academy courses vary based on the specific program. Detailed information about the schedule and duration can be found on the course page or by contacting the academy directly.',
    },
    {
        id: 2,
        value: 'item3',
        title: 'What is the class size for each session?',
        content: 'The class size at ExC Academy is tailored to ensure an optimal learning experience. While the exact number may vary by course, ExC Academy ensures personalized attention, interaction, and a collaborative learning environment.',
    },
    {
        id: 3,
        value: 'item4',
        title: 'What are the classes like?',
        content: 'Classes at ExC Academy are designed to be engaging, interactive, and focused on practical application. Instructors leverage a variety of teaching methods, including lectures, hands-on exercises, and group discussions. The goal is to create a dynamic learning atmosphere that fosters both individual growth and peer collaboration.',
    },
]

const HomeFAQ = [
    {
        id: 0,
        value: 'item1',
        title: 'What is the duration of the AI training program at ExC Academy?',
        content: 'The AI training program is structured to be completed within 1 month, allowing  you to work on a real-world project, integrating all learned concepts into a single workflow.',
    },
    {
        id: 1,
        value: 'item2',
        title: "What is the batch size for the AI training program?",
        content: 'We maintain small batch sizes to ensure personalized attention and a better learning experience for each participant.',
    },
    {
        id: 2,
        value: 'item3',
        title: 'Can I access course materials after completing the training?',
        content: 'Yes, you will have access to the LMS, course materials, and recorded sessions, allowing you to review and refresh your knowledge.',
    },
    {
        id: 3,
        value: 'item4',
        title: 'Will I receive a certificate after completing the course?',
        content: 'Yes, upon successful completion, you will receive an ExC Academy certification, which can be a valuable addition to your resume.',
    },
    {
        id: 4,
        value: 'item5',
        title: 'Are there opportunities for networking during the course?',
        content: 'Yes, you’ll have the chance to connect with peers, industry professionals, and alumni, fostering valuable networking opportunities.',
    },
    {
        id: 5,
        value: 'item6',
        title: 'What is the cost of the AI training program?',
        content: 'For detailed pricing and enrollment options, please contact us via the "talk to us" button on the homepage.',
    },
]

const ModulesFAQ = [
    {
        id: 0,
        value: 'item1',
        title: 'Module 1: Core Python',
        content: <ul className="pl-4 list-disc space-y-2">
            <li>Introduction to Python, Variables, Operators, Conditionals, Loops, Conversion, and Functions</li>
            <li>Strings, Lists, Tuples, Dictionaries, Functions, Modules and Files in Python</li>
            <li>Directories, Exception Handling, OOP, Regular Expressions, Frameworks, Data Analysis and Science Libraries</li>
        </ul>,
    },
    {
        id: 1,
        value: 'item2',
        title: "Module-2: Machine Learning",
        content:
            <ul className="pl-4 list-disc space-y-2">
                <li>Introduction to Machine Learning, Python for Machine Learning, Data Preprocessing and EDA</li>
                <li>Feature Engineering, Supervised & Unsupervised Learning Algorithms</li>
                <li>Model Evaluation and Validation, Hyperparameter Tuning, Introduction to Neural Networks</li>
            </ul>,
    },
    {
        id: 3,
        value: 'item3',
        title: 'Module-3: Deep Learning',
        content:
            <ul className="pl-4 list-disc space-y-2">
                <li>Introduction to Deep Learning, Python Libraries for Deep Learning, Neural Networks – Basics and training</li>
                <li>Convolutional, Recurrent and Advanced Neural Network Techniques</li>
                <li>Deployment of Deep Learning Models, Ethics & Challenges in Deep Learning and GANs</li>
            </ul>
        ,
    },
    {
        id: 4,
        value: 'item4',
        title: 'Module-4: Natural Language Processing (NLP)',
        content:
            <ul className="pl-4 list-disc space-y-2">
                <li>Introduction to NLP, Text Preprocessing Basics, Representing Text as Data</li>
                <li>Sentiment Analysis Basics, NER, Topic Modeling and Text Classification</li>
                <li>Advanced NLP Techniques, Introduction to Transformer Models and Deploying NLP Models</li>
            </ul>
        ,
    },
    {
        id: 5,
        value: 'item5',
        title: 'Module-5: Generative AI',
        content:
            <ul className="pl-4 list-disc space-y-2">
                <li>Introduction to Gen-AI, Basics of Generative Modeling, Text & Image - Generation Basics</li>
                <li>Deep Generative Models, Advanced Text and Image Generation</li>
                <li>Audio and Speech Generation, Ethics and Challenges in Gen-AI, Deploying Gen-AI Models</li>
            </ul>
        ,
    },
    {
        id: 6,
        value: 'item6',
        title: 'Module-6: ChatGPT & Prompt Engineering',
        content:
            <ul className="pl-4 list-disc space-y-2">
                <li>Introduction to ChatGPT and Large Language Models (LLMs)</li>
                <li>Basics of Prompt Engineering, Structuring Prompts Effectively</li>
                <li>Handling Limitations and Advanced Techniques</li>
            </ul>
        ,
    },
]

const AiFAQ = [
    {
        id: 0,
        value: 'item1',
        title: 'What will I learn in this workshop?',
        content: 'You’ll learn AI fundamentals, how to use ChatGPT, and how to apply AI tools to everyday tasks for business growth.',
    },
    {
        id: 1,
        value: 'item2',
        title: "Is this workshop suitable for beginners?",
        content: 'Yes! We’ve designed this workshop for professionals with zero AI experience.',
    },
    {
        id: 2,
        value: 'item3',
        title: 'Will I get a certificate?',
        content: 'Absolutely! You’ll receive an industry recognized certificate on attending this workshop to showcase your new skills.',
    },
]

const FaqData = ({ courseDetails, screen, data }: any) => {
    const [open, setOpen] = useState<any>({
        value: 0,
        status: 'active'
    });
    const handleOpen = (value: any) => {
        setOpen({
            value: open.value === value ? 0 : value,
            status: open.value === value ? 'inactive' : 'active'
        })
    };
    return (
        <Accordion type="single" defaultValue="item1" collapsible className="w-full">
            {courseDetails?.faq_detail ? <>
                {courseDetails?.faq_detail?.map((i: any) => (
                    <AccordionItem value={i.value} key={i.id}
                        onClick={(e: any) => handleOpen(i.id)}
                        className={`border-solid border-2 border-[#DBDBDB] px-5 mb-5 transition-bg  ${open.value === i.id && open.status === 'active' ? "bg-[#F5F5F5]" : ""}`}>
                        <AccordionTrigger className="lg:text-lg md:text-base text-[1rem] text-start" color={open.status === 'active' && open.value === i.id ? '#515151' : '#9014DC'} >{i.title}</AccordionTrigger>
                        <AccordionContent className="font-normal text-[14px] md:text-sm text-[#666666] leading-relaxed">
                            {i.content}
                        </AccordionContent>
                    </AccordionItem>
                ))}
            </> :
                screen === "home" ?
                    <>
                        {HomeFAQ.map((i) => (
                            <AccordionItem value={i.value} key={i.id}
                                onClick={(e: any) => handleOpen(i.id)}
                                className={`border-solid border-2 border-[#DBDBDB] px-5 mb-5 transition-bg ${open.value === i.id && open.status === 'active' ? "bg-[#F5F5F5]" : ""}`}>
                                <AccordionTrigger className="lg:text-lg md:text-base text-[1rem] text-start" color={open.status === 'active' && open.value === i.id ? '#515151' : '#9014DC'} >{i.title}</AccordionTrigger>
                                <AccordionContent className="font-normal text-[14px] md:text-sm text-[#666666] leading-relaxed">
                                    {i.content}
                                </AccordionContent>
                            </AccordionItem>
                        ))}</> :

                screen === "ai" ?
                    <>
                        {AiFAQ.map((i) => (
                            <AccordionItem value={i.value} key={i.id}
                                onClick={(e: any) => handleOpen(i.id)}
                                className={`border-solid border-2 border-[#DBDBDB] px-5 mb-5 transition-bg ${open.value === i.id && open.status === 'active' ? "bg-[#F5F5F5]" : ""}`}>
                                <AccordionTrigger className="lg:text-lg md:text-base text-[1rem] text-start" color={open.status === 'active' && open.value === i.id ? '#515151' : '#9014DC'} >{i.title}</AccordionTrigger>
                                <AccordionContent className="font-normal text-[14px] md:text-sm text-[#666666] leading-relaxed">
                                    {i.content}
                                </AccordionContent>
                            </AccordionItem>
                        ))}</> :

                    screen === "modules" ?
                        <>
                            {ModulesFAQ.map((i) => (
                                <AccordionItem value={i.value} key={i.id}
                                    onClick={(e: any) => handleOpen(i.id)}
                                    className={`border-solid border-2 border-[#DBDBDB] px-5 mb-5 transition-bg ${screen === 'modules' && 'bg-[#fff]'} ${open.value === i.id && open.status === 'active' ? "bg-[#F5F5F5]" : ""}`}>
                                    <AccordionTrigger className="lg:text-lg md:text-base text-[1rem] text-start" color={open.status === 'active' && open.value === i.id ? '#515151' : '#9014DC'} >{i.title}</AccordionTrigger>
                                    <AccordionContent className="font-normal text-[14px] md:text-sm text-[#666666] leading-relaxed">
                                        {i.content}
                                    </AccordionContent>
                                </AccordionItem>
                            ))}</> :
                        <>
                            {Data.map((i) => (
                                <AccordionItem value={i.value} key={i.id}
                                    onClick={(e: any) => handleOpen(i.id)}
                                    className={`border-solid border-2 border-[#DBDBDB] px-5 mb-5 transition-bg ${open.value === i.id && open.status === 'active' ? "bg-[#F5F5F5]" : ""}`}>
                                    <AccordionTrigger className="lg:text-lg md:text-base text-[1rem] text-start" color={open.status === 'active' && open.value === i.id ? '#515151' : '#9014DC'} >{i.title}</AccordionTrigger>
                                    <AccordionContent className="font-normal text-[14px] md:text-sm text-[#666666] leading-relaxed">
                                        {i.content}
                                    </AccordionContent>
                                </AccordionItem>
                            ))}</>
            }
        </Accordion>
    );
}
export default FaqData;